export const handleFocus = (
  ref: any,
  e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement | HTMLInputElement>,
  name?: any
) => {
  if (e.key === "Enter") {
    e.preventDefault();
    if (name !== null && name !== "" && name !== "0.00" && name?.length !== 0) {
      return ref?.current?.focus();
    }
  }
};
//add click button
export const handleClickAddButton = (
  e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement | HTMLInputElement>,
  focusButton: React.MutableRefObject<null>
) => {
  if (e.key === "enter") {
    // @ts-ignore
    focusButton?.current?.click();
  }
};
// focus array of elements
export const handleFocusNextElement = (
  e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement | HTMLInputElement>,
  inputRef: any,
  i: number
) => {
  if (e?.key === "Enter") {
    // @ts-ignore
    const next = inputRef.current[i + 1];
    if (next) {
      //@ts-ignore
      return next.focus();
    }
  }
};
